

<template>
  <b-container class="pt-5 bg-blue text-white fontStandard" fluid>
    <h3 class="text-center">
      <i class="big_text fas fa-user pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>{{myUsername}}</BTextUppercase>
        </strong>
      </b-badge>
    </h3>
    <br />

    <b-row>
      <b-col cols="12" xl="6" :align="responsiveAlignRight">
        <!--  -->

        <b-form-group class="max_width_400">
          <InputDisabledView label="Compte N°:" hover="Identifiant de compte" :input="`${myId}`" />
          <InputDisabledView label="Mon rôle:" hover="Mon rôle" :input="myRank" />
          <InputDisabledView
            label="Date de création:"
            hover="Date de création"
            :input="account.user.created"
          />
          <InputDisabledView
            label="Dernière connexion:"
            hover="Dernière connexion"
            :input="account.user.last_connected"
          />
        </b-form-group>

        <!--  -->
      </b-col>
      <b-col cols="12" xl="6" :align="responsiveAlignLeft">
        <!--  -->

        <b-form-group class="max_width_400">
          <InputDisabledView label="Nom complet:" hover="Nom complet" :input="myFullName" />
          <InputDisabledView label="Solde virtuel €:" hover="Solde virtuel €" :input="`${0}`" />
        </b-form-group>

        <!--  -->
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6" :align="responsiveAlignRight">
        <b-form-group class="max_width_400">
          <b-button
            type="submit"
            variant="info"
            @click="askPersonnalData"
            block
          >Récuperer vos données personnelles</b-button>
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="6" :align="responsiveAlignLeft">
        <b-form-group class="max_width_400">
          <b-button type="submit" variant="danger" @click="closeAccount" block>Clôturer le compte</b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: {
    BTextUppercase: () => import("@/components/BTextUppercase"),
    InputDisabledView: () => import("@/components/InputDisabledView"),
  },
  mixins: [Form],
  mounted() {
    this.loadContent();
  },
  beforeDestroy() {
    this.content = this.current_passwd = this.passwd1 = this.passwd2 = this.extra_user_info = this.captcha = undefined;
  },
  computed: {
    ...mapState(["account", "events"]),
    isMobileAspect() {
      return this.events.width < 1200 ? true : false;
    },
    responsiveAlignLeft() {
      return this.isMobileAspect ? "center" : "left";
    },
    responsiveAlignRight() {
      return this.isMobileAspect ? "center" : "right";
    },

    myId() {
      const data = this.account.axios_data;
      return this.account.axios_data && data.content && data.content.id
        ? data.content.id
        : -1;
    },
    myUsername() {
      const data = this.account.user;
      if (this.myId !== -1) {
        return data.username;
      } else return "";
    },
    myGender() {
      const data = this.account.extraInfo;
      if (this.myId !== -1) {
        return data.gender === "male" ? "Mr" : "Mme";
      } else return "";
    },
    myFullName() {
      const data = this.account.extraInfo;
      return this.myId !== -1
        ? this.myGender + " " + data.firstName + " " + data.lastName
        : "";
    },
    myRegistration() {
      const data = this.account.user;
      return this.myId !== -1 ? data.created : "";
    },
    myRank() {
      const data = this.account.user;
      return this.myId !== -1 ? data.rank : "";
    },
  },
  methods: {
    closeAccount() {
      this.$bvModal
        .msgBoxConfirm(
          "Vous souhaitez cloturer votre compte, cette action est irreversible ",
          {
            title: "Cloturer le compte",
            size: "md",
            buttonSize: "md",

            okTitle: "Je confirme cloturer mon compte",
            cancelTitle: "Non j'annule",
            footerClass: "p-2",

            noStacking: true,

            headerBgVariant: "dark",
            bodyBgVariant: "dark",
            footerBgVariant: "dark",
            cancelVariant: "danger",
            okVariant: "success",
            headerCloseVariant: "light",
            headerTextVariant: "light",
            headerBorderVariant: "dark",
            footerBorderVariant: "dark",

            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) this.closeAccountData();
          else
            this.$root.showToast(
              `Opération annulée`,
              `Ouf, nous avons frolé la catastrophe.`,
              "info",
              10000
            );
        })
        .catch((err) => {
          this.$root.internalError();
        });
    },
    async closeAccountData() {
      await this.$root.axios("delete", "api/account/close");

      const axios = this.account.axios_data;

      if (axios && axios.auth_response === "send_mail_closing_account") {
        this.$root.showToast(
          `Un mail vous a été envoyé`,
          `Si vous souhaitez fermer votre compte, vous devrez le confirmer avec le mail que vous allez recevoir, attention vous avez 5 minutes pour le confirmer, autrement il sera annulé par sécurité`,
          "warning",
          30000
        );
      } else {
        this.$root.showToast(
          `Une erreur s'est produite`,
          `L'opération n'a pu aboutir`,
          "danger",
          10000
        );
      }
    },
    async askPersonnalData() {
      await this.$root.axios("post", "api/recovered/personnal/data");

      const axios = this.account.axios_data;

      if (axios && axios.auth_response === "send_mail_personnal_data_ready") {
        this.$root.showToast(
          `Un mail vous a été envoyé`,
          `Un mail contenant le lien pour vos données personnelles a été envoyé, attention vous avez 5 minutes pour cliquer sur le lien, autrement il sera annulé par sécurité`,
          "warning",
          30000
        );
      } else {
        this.$root.showToast(
          `Une erreur s'est produite`,
          `L'opération n'a pu aboutir`,
          "danger",
          10000
        );
      }
    },
  },
};
</script>
